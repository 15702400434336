<template>
  <HXContentCard title="Honorarios Exito / Produccion">
    <!-- Filtros -->
    <div class="row m-3">
      <div class="col-2 px-0">
        <h4 class="mb-2"><b>Mes:</b></h4>
        <b-select
          v-model="mesSeleccionado"
          class="form-control bg-dark personalizado"
          :options="listaMes"
          text-field="mes"
          value-field="id"
        />
      </div>
      <div class="col-1 px-1">
        <h4 class="mb-2"><b>Año:</b></h4>
        <b-select
          v-model="anioSeleccionado"
          class="form-control bg-dark personalizado"
          :options="listaAnios"
        />
      </div>
      <div class="col-1 px-0 mt-3">
        <vs-button primary icon class="px-2" @click="filtrar(1)" id="sendform2">
          <i class="fas fa-search"></i>
        </vs-button>
      </div>
      <div class="col-6" v-if="isAdmin">
        <h4 class="mb-2"><b>Filtrar x profesional:</b></h4>
        <v-select
          ref="selectProfesional"
          @input="filtrar(2)"
          class="style-chooser"
          :reduce="(value) => value"
          v-model="profesional"
          :options="listaProfesional"
          label="nombreProfesional"
          placeholder="-- selecciona un profesional --"
        >
          <div slot="no-options">No se encontraron resultados!</div>
        </v-select>
      </div>

      <div class="col px-1 mt-3 d-flex justify-content-end">
        <vs-button
          success
          flat
          icon
          :to="{ name: 'AgregarArchivoCuentaCobro' }"
        >
          <i class="fas fa-upload mr-1"></i>
          Cargar soportes
        </vs-button>
      </div>
    </div>

    <section class="p-4">
      <!-- TOTALES HONORARIOS -->
      <div class="d-flex mb-3" style="gap: 1rem">
        <div
          v-for="item in totalesHonorarios"
          :key="item.id"
          class="py-2 px-4 bg-dark rounded"
        >
          <span>{{ item.label }}</span>
          <br />
          <h2 class="font-weight-bold text-white-50">
            {{
              item.value.toLocaleString("es-CO", {
                style: "currency",
                currency: "COP",
              })
            }}
          </h2>
        </div>
      </div>

      <div class="m-0" v-for="sect in sectionsList" :key="sect.id">
        <div
          class="d-flex align-items-center py-3 border-bottom"
          v-b-toggle="'collapse-h-' + sect.id"
        >
          <i
            :class="`fas fa-caret-${
              sect.collased ? 'down incline' : 'right'
            } text-primary mr-1 fa-lg`"
          ></i>
          <h3>
            <b>{{ sect.label }}</b>
          </h3>
        </div>

        <!-- COLLAPSE -->
        <b-collapse v-model="sect.collased" :id="'collapse-h-' + sect.id">
          <!-- HONORARIOS PRODUCCION -->
          <div v-if="sect.id == 1" class="m-3">
            <vs-button
              v-if="profesional"
              class="mt-2"
              icon
              flat
              @click="verResumen()"
            >
              <i class="fas fa-file-lines mr-1"></i>
              Ver Resumen
            </vs-button>

            <section>
              <!-- tablas produccion -->
              <div v-for="item in tablesProduccion.tables" :key="item.id">
                <div class="m-3">
                  <h4>
                    <b>{{ item.id }}. {{ item.title }}</b>
                  </h4>
                </div>
                <vs-table striped>
                  <template #notFound>
                    <span>No se encontraron resultados</span>
                  </template>
                  <template #header>
                    <vs-input
                      v-model="item.search"
                      border
                      placeholder="Buscar..."
                    />
                  </template>
                  <template #thead>
                    <vs-tr>
                      <vs-th
                        v-for="(th, idx) in tablesProduccion.columns"
                        :key="idx"
                        sort
                        @click="item.data = $vs.sortData($event, item.data, th)"
                        >{{ th }}</vs-th
                      >
                    </vs-tr>
                  </template>
                  <template #tbody>
                    <vs-tr
                      v-for="row in $vs.getPage(
                        $vs.getSearch(item.data, item.search),
                        page,
                        max
                      )"
                      :data="row"
                      :key="row.id"
                    >
                      <vs-td>
                        {{ row.rut }}
                      </vs-td>
                      <vs-td>
                        {{ row.pacienteNombre }}
                      </vs-td>
                      <vs-td>
                        {{ row.concepto }}
                      </vs-td>
                      <vs-td>
                        {{ row.descripcion }}
                      </vs-td>
                      <vs-td>
                        {{ row.tipo }}
                      </vs-td>
                      <vs-td>
                        {{
                          row.valor.toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        }}
                      </vs-td>
                    </vs-tr>
                  </template>
                  <template v-if="item.data?.length > 0" #footer>
                    <vs-pagination
                      v-model="page"
                      :length="
                        $vs.getLength(
                          $vs.getSearch(item.data, item.search),
                          max
                        )
                      "
                    />
                  </template>
                </vs-table>
              </div>
            </section>
          </div>

          <!-- HONORARIOS EXITO -->
          <div v-else class="mt-3">
            <vs-table v-if="honorariosExitoData.length > 0" striped>
              <template #thead>
                <vs-tr>
                  <vs-th
                    v-for="header in headersExito"
                    :key="header"
                    :colspan="encabezadosCombinados(header)"
                  >
                    {{ header }}
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr
                  v-for="(row, index) in honorariosExitoData"
                  :data="row"
                  :key="index"
                >
                  <vs-td v-for="(cell, idx) in row" :key="idx">
                    {{ cell | formatValue(idx) }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

            <not-found v-else />
          </div>
        </b-collapse>
      </div>
    </section>

    <!-- modal resumen -->
    <b-modal
      :title="'Resumen: ' + cadenaMes() + ' ' + anioSeleccionado"
      id="resumen"
      size="lg"
      hide-footer
    >
      <div class="p-2 mb-3 text-center">
        <h3>
          <b> {{ profesional?.nombreProfesional || "" }}</b>
        </h3>
      </div>

      <div class="row px-3">
        <div v-for="rsm in tablesResumen" :key="rsm.id" class="col-6 px-1">
          <table class="table table-bordered text-center table-striped">
            <thead>
              <tr>
                <th>
                  {{ rsm.title }}
                  {{ rsm.id === 8 ? `(${PorcentajeSucursal}%)` : "" }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h3>
                    <b>
                      {{
                        rsm.total.toLocaleString("es-CO", {
                          style: "currency",
                          currency: "COP",
                        })
                      }}</b
                    >
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </HXContentCard>
</template>

<script>
import * as XLSX from "xlsx";
import {
  tablesProduccion,
  tablesResumen,
  sectionsList,
  headersExito,
} from "./tablesSchema.js";

import { listaMes } from "@/config/constants.js";

export default {
  components: {
    notFound: () => import("@/components/helexium/notFound.vue"),
  },
  data() {
    return {
      page: 1,
      max: 10,
      tablesProduccion: { ...tablesProduccion },
      tablesResumen: [...tablesResumen],
      sectionsList: [...sectionsList],
      headersExito: [...headersExito],
      listaMes: [...listaMes],
      honorariosExitoData: [],
      listaAnios: [],
      mesSeleccionado: null,
      anioSeleccionado: null,
      persona: {},
      listaPacientes: [],
      listaProfesional: [],
      listaSucursal: [],
      listaItems: [],
      listaItemsbk: [],
      profesional: null,
      sucursal: null,
      listaItemsMaterial: [],
      listaLaboratorio: [],
      listaLaboratorioBk: [],
      listaItemsRemision: [],
      listattos: [],
      Periodo: {},
      otrosConceptos: [],
      pacienteManual: "",
      totalAgregados: 0,
      totalDescuentos: 0,
      totalTtosRealizados: 0,
      totalCostosMaterial: 0,
      totalCostosRemision: 0,
      totalCostosLaboratorio: 0,
      totalNetoHonorarios: 0,
      PorcentajeSucursal: 0,
      TotalHonorarios: 0,
    };
  },
  async mounted() {
    this.getListaAnios();

    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
  },
  computed: {
    isAdmin() {
      return this.persona.rol.nivel >= 3;
    },
    totalesHonorarios() {
      const honorariosExitoTotal =
        this.honorariosExitoData.length > 0
          ? this.honorariosExitoData.reduce(
              (total, row) => total + row[row.length - 1],
              0
            )
          : 0;

      const totalHonorarios = this.TotalHonorarios || 0;

      const totalGeneral = totalHonorarios + honorariosExitoTotal;

      return [
        { id: 1, label: "Honorarios de Producción", value: totalHonorarios },
        { id: 2, label: "Honorarios de Éxito", value: honorariosExitoTotal },
        { id: 3, label: "Total Honorarios", value: totalGeneral },
      ];
    },
  },
  methods: {
    getListaAnios() {
      const anioActual = new Date().getFullYear();
      const anioInicio = anioActual - 2;
      const listaAnios = [];

      for (let anio = anioInicio; anio <= anioActual; anio++) {
        listaAnios.push(anio);
      }
      this.listaAnios = listaAnios;
    },
    async getHonorariosExitoTemp(anio = null, mes = null) {
      try {
        this.$isLoading(true);

        // Mejorar la legibilidad de la creación de path
        const path =
          anio && mes
            ? `HonorariosExitoTemp/Obtener/${anio}/${mes}`
            : "HonorariosExitoTemp/Obtener";
        let res = await this.$store.dispatch("denti_get", { path });

        console.log("res", res);

        const { success, data } = res;
        if (success && data !== null) {
          // Parsear los datos de Excel
          const parsedData = JSON.parse(data.data);

          console.log("parsedData", parsedData);

          const foundData = parsedData.excelData.filter(
            (row, idx) => row[1] == this.profesional.rut || idx === 0
          );

          console.log("foundData", foundData);

          this.honorariosExitoData = foundData;

          console.log("honorariosExitoData", this.honorariosExitoData);
        } else {
          console.log("No se encontraron datos");
          this.honorariosExitoData = [];
        }

        this.$isLoading(false);
      } catch (error) {
        console.error("Error al obtener los honorarios:", error);
        this.$isLoading(false);
        this.showToast("error", "Hubo un error al obtener los honorarios");
      }
    },
    encabezadosCombinados(header) {
      if (header == "CIERRES DE ORTODONCIA") return 9;
      if (header == "IMPLANTES MONTADOS") return 2;
      if (header == "MONTAJES DE ORTODONCIA") return 4;
      if (header == "PACIENTES QUE INICIAN") return 3;
    },
    cadenaMes() {
      let numero = this.mesSeleccionado;
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      // Verificar que el número esté en el rango válido (1-12)
      if (numero < 1 || numero > 12) {
        return "Número inválido. Debe estar entre 1 y 12.";
      }

      // Restar 1 para obtener el índice correcto del mes
      return meses[numero - 1];
    },
    verResumen() {
      this.$bvModal.show("resumen");
    },
    showToast(type, message) {
      console.log(type);
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      if (type == "warning")
        this.$toast.warning(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    exportToExcel() {
      // Crear las hojas de datos de cada array con títulos y sumatorias
      const sheet1 = this.createSheetWithSum(
        this.listaItems,
        "Prestaciones",
        "valorPrestacion"
      );
      const sheet2 = this.createSheetWithSum(
        this.listaItemsMaterial,
        "Material Especial",
        "valor"
      );
      const sheet3 = this.createSheetWithSum(
        this.listaLaboratorio,
        "Costos Laboratorio",
        "valorPrestacion"
      );

      // Crear un nuevo libro de trabajo
      const wb = XLSX.utils.book_new();

      // Agregar cada hoja de trabajo con el nombre deseado
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.aoa_to_sheet(sheet1),
        "Prestaciones"
      );
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.aoa_to_sheet(sheet2),
        "Material Especial"
      );
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.aoa_to_sheet(sheet3),
        "Costos Laboratorio"
      );

      // Exportar el archivo Excel
      // XLSX.writeFile(wb, 'datos_separados.xlsx');
      XLSX.writeFile(
        wb,
        "Liquidación-" +
          this.profesional +
          "-" +
          this.mesSeleccionado +
          "-" +
          this.anioSeleccionado +
          "-" +
          this.sucursal +
          ".xlsx"
      );
    },

    // Función para crear una hoja con sumatoria
    createSheetWithSum(data, title, sumColumn) {
      // Verificar si el array de datos está vacío
      if (!data || data.length === 0) {
        console.warn(`El array ${title} está vacío o no está definido.`);
        return [[title], ["No hay datos disponibles"]]; // Retorna un mensaje si el array está vacío
      }

      const headers = Object.keys(data[0]); // Obtener encabezados de las claves del array
      const sheetData = [];

      // Agregar los encabezados de las columnas
      sheetData.push(headers);

      // Agregar los datos del array
      data.forEach((item) => {
        sheetData.push(headers.map((key) => item[key]));
      });

      // Calcular y agregar la fila de sumatoria
      const sumRow = headers.map((key) =>
        key === sumColumn ? this.sumColumn(data, key) : ""
      );
      sheetData.push(["Total", ...sumRow.slice(1)]); // Agregar 'Total' y la sumatoria

      return sheetData;
    },

    // Función para calcular la sumatoria de una columna específica
    sumColumn(data, column) {
      return data.reduce((sum, item) => {
        const value = Number(item[column]); // Convertir a número
        return sum + (isNaN(value) ? 0 : value); // Sumar solo si es un número válido
      }, 0);
    },

    async filtrar(item) {
      this.otrosConceptos = [];
      this.listaItems = [];
      if (item == 1) {
        this.profesional = null;
        this.sucursal = null;
        ////////
        this.$isLoading(true);
        //filtrar x mes y año
        let mesSeleccionado = new Date(
          this.anioSeleccionado + "/" + this.mesSeleccionado + "/01"
        ).getMonth();
        let anioSeleccionado = this.anioSeleccionado; //new Date().getFullYear();
        this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(
          anioSeleccionado,
          mesSeleccionado
        );
        await this.getData(this.Periodo.primerDia, this.Periodo.ultimoDia);

        this.$isLoading(false);
      }
      if (item == 2 || !this.isAdmin) {
        console.log("ok");

        console.log("profesional", this.listaProfesional);

        if (!this.isAdmin) {
          this.profesional = this.listaProfesional.find(
            (x) => x.rut === this.persona.numeroDocumento
          );
        }

        console.log(this.profesional);

        //problema: no existe rut del profesional en prestaciones
        try {
          this.$isLoading(true);

          //filtrando materiales si ha seleccionado profesional y sucursal
          let mesSeleccionado = new Date(
            this.anioSeleccionado + "/" + this.mesSeleccionado + "/01"
          ).getMonth();
          let anioSeleccionado = this.anioSeleccionado; //new Date().getFullYear();
          this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(
            anioSeleccionado,
            mesSeleccionado
          );
          console.log("periodo", this.Periodo);
          await this.obtenerData(
            this.Periodo.primerDia,
            this.Periodo.ultimoDia
          );
          // obtener otros conceptos
          await this.obtenerOtrosConceptos(
            this.Periodo.primerDia,
            this.Periodo.ultimoDia
          );
          this.listattos = this.listaItems.filter(
            (x) => x.concepto == "Ttos. realizados, cancelados y evolucionados"
          );

          this.tablesProduccion.tables[0].data = this.listattos;

          this.listaItemsMaterial = this.listaItemsbk.filter(
            (x) => x.concepto == "Costos material especial"
          );

          this.tablesProduccion.tables[1].data = this.listaItemsMaterial;

          console.log("material ", this.listaItemsMaterial);
          this.listaItemsRemision = this.listaItems.filter(
            (x) => x.concepto == "Costos de remisión"
          );

          console.log("remision--> ", this.listaItemsRemision);
          this.tablesProduccion.tables[2].data = this.listaItemsRemision;

          this.listaLaboratorio = this.listaItems.filter(
            (x) => x.concepto == "Costos Laboratorio"
          );
          console.log("listaLaboratorio ", this.listaLaboratorio);

          // totalizando datos

          this.totalAgregados = 0;
          this.totalDescuentos = 0;
          // this.otrosConceptos.forEach((item) => {
          //   if (item.tipo === "Descuento") {
          //     this.totalDescuentos += item.valor;
          //   }
          //   // else if (item.tipo === "Agregado") {
          //   //   this.totalAgregados += item.valor;
          //   // }
          // });
          //// CALCULANDO AGREGADOS Y DESCUENTOS DE OTROS CONCEPTOS
          let listaAgregados = this.otrosConceptos.filter(
            (x) => x.tipo != "Descuento"
          );
          let totalAgregados = listaAgregados.reduce(
            (accumulator, currentItem) => {
              const valueToAdd = parseFloat(currentItem.valor);
              return accumulator + valueToAdd;
            },
            0
          );
          //this.totalAgregados = totalAgregados;

          let listaDescuentos = this.otrosConceptos.filter(
            (x) => x.tipo == "Descuento"
          );
          let totalDescuentos = listaDescuentos.reduce(
            (accumulator, currentItem) => {
              const valueToAdd = parseFloat(currentItem.valor);
              return accumulator + valueToAdd;
            },
            0
          );
          //this.totalDescuentos = totalDescuentos;

          //CALCULANDO AGREGADOS Y DESCUENTOS DE LISTAITEMS
          let listaAgregadosttos = this.listaItems.filter(
            (x) => x.tipo != "Descuento"
          );
          let totalAgregadosttos = listaAgregadosttos.reduce(
            (accumulator, currentItem) => {
              const valueToAdd = parseFloat(currentItem.valor);
              return accumulator + valueToAdd;
            },
            0
          );

          let listaDescuentosttos = this.listaItems.filter(
            (x) => x.tipo == "Descuento"
          );
          let totalDescuentosttos = listaDescuentosttos.reduce(
            (accumulator, currentItem) => {
              const valueToAdd = parseFloat(currentItem.valor);
              return accumulator + valueToAdd;
            },
            0
          );

          this.totalDescuentos =
            Number(totalDescuentosttos) + Number(totalDescuentos);
          this.totalAgregados =
            Number(totalAgregadosttos) + Number(totalAgregados);
          this.totalNetoHonorarios =
            Number(this.totalAgregados) - Number(this.totalDescuentos);

          this.TotalHonorarios =
            (this.totalNetoHonorarios * this.PorcentajeSucursal) / 100;

          ///calculos totales///////////////////////////////////////////
          //OK
          this.totalTtosRealizados = this.listattos.reduce(
            (accumulator, currentItem) => {
              const valueToAdd = parseFloat(currentItem.valor);
              return accumulator + valueToAdd;
            },
            0
          );

          this.totalCostosMaterial = this.listaItemsMaterial.reduce(
            (accumulator, currentItem) => {
              const valueToAdd = parseFloat(currentItem.valor);
              return accumulator + valueToAdd;
            },
            0
          );

          this.totalCostosRemision = this.listaItemsRemision.reduce(
            (accumulator, currentItem) => {
              const valueToAdd = parseFloat(currentItem.valor);
              return accumulator + valueToAdd;
            },
            0
          );

          this.totalCostosLaboratorio = this.listaLaboratorio.reduce(
            (accumulator, currentItem) => {
              const valueToAdd = parseFloat(currentItem.valor);
              return accumulator + valueToAdd;
            },
            0
          );
          // let sumatorias = parseFloat(this.listattos) + parseFloat(this.totalAgregados);
          // let descuentos = parseFloat(this.totalCostosLaboratorio) + parseFloat(this.totalCostosRemision) + parseFloat(this.totalDescuentos);

          //this.totalNetoHonorarios = ; //sumatorias - descuentos;

          this.tablesResumen[0].total = this.totalTtosRealizados;
          this.tablesResumen[1].total = this.totalCostosMaterial;
          this.tablesResumen[2].total = this.totalCostosRemision;
          this.tablesResumen[3].total = this.totalCostosLaboratorio;
          this.tablesResumen[4].total = this.totalAgregados;
          this.tablesResumen[5].total = this.totalDescuentos;
          this.tablesResumen[6].total = this.totalNetoHonorarios;
          this.tablesResumen[7].total = this.TotalHonorarios;

          await this.getHonorariosExitoTemp(
            this.anioSeleccionado,
            this.mesSeleccionado
          );

          this.$isLoading(false);
        } catch (error) {
          this.$isLoading(false);
        }
        //console.log("profesional data", this);
        // idProfesional = idProfesional[0].rut;
        // console.log("profesional data", idProfesional);
      }
    },
    async obtenerData(primerDia, ultimoDia) {
      console.log("proff", this.profesional.nombreProfesional);
      this.listaItems = [];
      let res = await this.$store.dispatch("denti_get", {
        path:
          "Prestaciones/MiHonorarioPorProfesionalFecha/" +
          this.profesional.nombreProfesional +
          "/" +
          primerDia +
          "/" +
          ultimoDia,
      });
      console.log("otros", res);

      this.PorcentajeSucursal = res[0].porcentajeLiquidacion;

      this.listaItems = res;
      this.listaItemsbk = res;
    },
    async obtenerOtrosConceptos(primerDia, ultimoDia) {
      this.otrosConceptos = [];
      let res = await this.$store.dispatch("denti_get", {
        path:
          "Prestaciones/ListaOtrosConceptosPorProfesional/" +
          primerDia +
          "/" +
          ultimoDia +
          "/" +
          this.profesional.nombreProfesional +
          "/",
      });
      console.log("otros", res);
      this.otrosConceptos = res;
      this.tablesProduccion.tables[3].data = this.otrosConceptos;
    },

    obtenerPrimerYUltimoDiaDelMes(anio, mes) {
      // El mes en JavaScript es base 0, por lo que enero es 0 y diciembre es 11
      const primerDia = new Date(anio, mes, 1);
      const ultimoDia = new Date(anio, mes + 1, 0); // El día 0 del mes siguiente es el último día del mes actual

      return {
        primerDia: primerDia.toISOString().split("T")[0], // Formato YYYY-MM-DD
        ultimoDia: ultimoDia.toISOString().split("T")[0],
      };
    },
    initValuesData() {
      this.listaProfesional = [];
      this.listattos = [];
      this.totalAgregados = 0;
      this.totalDescuentos = 0;
      this.totalNetoHonorarios = 0;
      this.TotalHonorarios = 0;
      this.totalTtosRealizados = 0;
      this.totalCostosMaterial = 0;
      this.totalCostosRemision = 0;
      this.totalCostosLaboratorio = 0;
      this.honorariosExitoData = [];

      this.tablesProduccion.tables.forEach((table) => {
        table.data = [];
      });
    },
    async getData(primerDia, ultimoDia) {
      try {
        this.listaItems = [];
        // console.log("getdata dentisalud", Periodo);
        let res = await this.$store.dispatch("denti_get", {
          path:
            "Prestaciones/ListaProfesionalesPorPeriodo/" +
            primerDia +
            "/" +
            ultimoDia,
        });
        console.log("res dentisalud ", res);

        if (res.length == 0) {
          this.initValuesData();
          return;
        }

        this.listaProfesional = res.map((x) => ({
          // console.log(x.profesional.nombreProfesional);
          nombreProfesional: x.profesional.nombreProfesional.toUpperCase(),
          rut: x.profesional.rut,
        }));

        // desplegar el select
        this.$refs.selectProfesional.$refs.search.focus();

        // this.listaSucursal = res.map((pa) => pa.sucursalPpto).filter((nombrep, indexp, selfp) => selfp.indexOf(nombrep) === indexp);
        // this.listaSucursal.sort((a, b) => {
        //   if (a < b) return -1;
        //   if (a > b) return 1;
        //   return 0;
        // });

        // console.log("pacientes ", this.listaPacientes);
        // console.log("list registroServicios...", res);
        // if (res.success) {
        //   this.listaItems = res.data.map((service) => {
        //     return {
        //       ...service,
        //       placa: this.getPlacaVehiculo(service.placaId),
        //       estado: service.fechaFin ? true : false,
        //     };
        //   });
        //   // console.log(this.listaItems);
        // } else {
        //   this.listaItems = [];
        // }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        this.listaItems = [];
        console.log("err dentisalud ", error);
      }
    },
  },
  filters: {
    formatValue(value, idx) {
      console.log("value", value);

      if (value === undefined || value === null) return "";

      const formatters = {
        currency: [
          6, 7, 10, 11, 12, 13, 14, 15, 16, 21, 25, 27, 31, 34, 35, 37, 40,
        ],
        date: [3],
        percentage: [8, 9, 20, 30],
        numberWithCommas: [4, 19, 29, 33],
      };

      if (typeof value === "number") {
        if (formatters.currency.includes(idx)) {
          // Formatear como moneda
          return new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            maximumFractionDigits: 0,
          }).format(value);
        } else if (formatters.date.includes(idx)) {
          // Formatear como fecha (en caso de que sea un valor numérico de fecha de Excel)
          const excelEpoch = new Date(1899, 11, 30); // La fecha base de Excel es el 30 de diciembre de 1899
          const date = new Date(excelEpoch.getTime() + value * 86400000); // Convertir a milisegundos
          return date.toLocaleDateString("es-CO"); // Formatear la fecha en el formato deseado
        } else if (formatters.percentage.includes(idx)) {
          // Formatear como porcentaje
          return (value * 100).toFixed(1) + "%";
        } else if (formatters.numberWithCommas.includes(idx)) {
          // Redondear a un decimal y luego formatear con separadores de miles
          return new Intl.NumberFormat("es-CO").format(
            Math.round(value * 10) / 10
          );
        }
      }

      return value;
    },
  },
};
</script>
