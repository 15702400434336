export const tablesProduccion = {
  tables: [
    {
      id: 1,
      title: "Ttos. realizados, cancelados y evolucionados",
      data: [],
      search: "",
    },
    {
      id: 2,
      title: "Costos material especial",
      data: [],
      search: "",
    },
    {
      id: 3,
      title: "Costos de remisión",
      data: [],
      search: "",
    },
    {
      id: 4,
      title: "Otros conceptos",
      data: [],
      search: "",
    },
  ],
  columns: [
    "Identificación Paciente",
    "Nombre Paciente",
    "Concepto",
    "Descripción",
    "Tipo",
    "Valor",
  ],
};

export const tablesResumen = [
  {
    id: 1,
    title: "Ttos. realizados, cancelados y evol.",
    total: 0,
  },
  {
    id: 2,
    title: "Costos material especial",
    total: 0,
  },
  {
    id: 3,
    title: "Costos de remisión",
    total: 0,
  },
  {
    id: 4,
    title: "Costos de laboratorio",
    total: 0,
  },
  {
    id: 5,
    title: "Total agregados",
    total: 0,
  },
  {
    id: 6,
    title: "Total descuentos",
    total: 0,
  },
  {
    id: 7,
    title: "Total neto honorarios",
    total: 0,
  },
  {
    id: 8,
    title: "Total honorarios",
    total: 0,
  },
];
export const sectionsList = [
  {
    id: 1,
    label: "Honorarios de Produccion",
    collased: true,
  },
  {
    id: 2,
    label: "Honorarios de Exito",
    collased: true,
  },
];

export const headersExito = [
  "CLINICA",
  "IDENTIFICACION",
  "NOMBRE",
  "FECHA DE INGRESO",
  "TIEMPO DE PRESTACION DE SERVICIOS",
  "CATEGORIA",
  "PRIMEROS ABONOS PARA CUMPLIMIENTO",
  "META DE PRIMEROS ABONOS",
  "CUMPLIMIENTO",
  "PORCENTAJE A CANCELAR",
  "PRIMEROS ABONOS LIQUIDACION",
  "SUBVENCION Y GASTOS FINANCIEROS",
  "DEVOLUCIONES",
  "TOTAL PRIMEROS ABONOS LIQUIDACION",
  "NOTA DEBITO CONVENIOS / ALIANZAS",
  "INCENTIVO DE PRIMEROS ABONOS SIN CUMPLIMIENTO DE META",
  "INCENTIVO DE PRIMEROS ABONOS CON CUMPLIMIENTO DE META",
  "CIERRES DE ORTODONCIA",
  "IMPLANTES MONTADOS",
  "MONTAJES DE ORTODONCIA",
  "PACIENTES QUE INICIAN",
  "HONORARIOS DE ÉXITO PACKS OCTUBRE",
  "GARANTIZADO",
  "NOTA DEBITO CONVENIOS / ALIANZAS",
  "INCREMENTO OCACIONAL",
  "OTROS",
  "TOTAL",
];
